import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Spinner, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import pageSvg from "../assets/svg/forgot_password.svg";
import pageDown from "../assets/svg/qa_engineers.svg";

export default function MailboxPassword() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [currentEmail, setCurrentEmail] = useState(email);
  const [isValidToken, setIsValidToken] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setResponseMessage("As senhas não coincidem.");
      return;
    }

    if (password === "") {
      setResponseMessage("A senha não pode estar em branco.");
      return;
    }

    setIsUpdating(true);

    const url = `https://api.emailfacil.com.br/mailbox/set/${token}`;
    const requestBody = {
      email,
      password,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok) {
        setResponseMessage("Senha atualizada com sucesso.");
        setIsFormVisible(false);
      } else {
        setResponseMessage(responseData.error || "Falha ao atualizar a senha.");
      }
    } catch (error) {
      setResponseMessage("Ocorreu um erro. Por favor, tente novamente.");
    } finally {
      setIsUpdating(false); 
    }
  };

  
// Realizar a requisição para verificar o token
useEffect(() => {
  const checkToken = async () => {
    const checkUrl = `https://api.emailfacil.com.br/mailbox/check/${token}/${email}`;

    try {
      const response = await fetch(checkUrl, {
        method: "GET",
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.validity === false) {
          setIsValidToken(false); // Token inválido
        } else {
          setIsValidToken(true); // Token válido
        }
      } else {
        setIsValidToken(false); // Token inválido
      }
    } catch (error) {
      setIsValidToken(false); // Erro na requisição, considerar token inválido
    }
  };

  checkToken();
}, [token, email]);

return (
  <div className="page-auth">
    <div className="header">
      {/* Restante do seu componente permanece o mesmo */}
    </div>

    <div className="content">
      <Container>
        <Card className="card-auth">
          <Card.Body className="text-center">
            
            {isValidToken === null ? (
              <h2>Verificando token...</h2>
            ) : isValidToken ? (
              <>
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"
                ></object>
              </div>
                <Card.Title>Atualize a senha do email:</Card.Title>
                <div>
                  <h2>{currentEmail}</h2>
                </div>
                {isFormVisible && (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="password">
                      <Form.Control
                        type="password"
                        placeholder="Digite a nova senha"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                      <Form.Control
                        type="password"
                        placeholder="Confirme a nova senha"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                    </Form.Group>
                    <Row className="g-2">
                      <Col sm="12">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={
                            password === "" || password !== confirmPassword
                          }
                        >
                          {isUpdating ? (
                            // Se estiver atualizando, exibe o Spinner de carregamento
                            <>
                            Atualizando... 
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </>
                          ) : (
                          "Atualizar"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
                {responseMessage && <div>{responseMessage}</div>}
              </>
            ) : (
              <div className="mb-5">
              <object
                type="image/svg+xml"
                data={pageDown}
                className="w-50"
                aria-label="svg image"
              ></object>
              <h2>Token inválido</h2>
            </div>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  </div>
);
}