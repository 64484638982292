import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import MailboxUpdate from '../MailboxUpdate/MailboxUpdate';

const MailboxUpdateModal = ({ showModal, handleClose, mailbox }) => { 
    const [modalShow, setModalShow] = useState(showModal);
    const [selectedMailbox, setSelectedMailbox] = useState(null);

    useEffect(() => {
        setModalShow(showModal);
    }, [showModal]);

    useEffect(() => {
        setSelectedMailbox(mailbox);
    }, [mailbox]);


    // Atualize o nome da função para usar showModal
    const handleCloseModal = () => {
        setModalShow(false);
        handleClose(); // Use showModal aqui
      };
    
  return (
    <Modal show={modalShow} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Senha do Mailbox</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h3>{mailbox && mailbox.email}</h3>
        {/* Outros detalhes do mailbox aqui, se necessário */}
        <p>Preencha os campos abaixo com a nova senha, repita a mesma senha em amos os campos.</p>
        <MailboxUpdate email={selectedMailbox && selectedMailbox.email} />
        
        {/* <MailboxUpdate /> */}
      </Modal.Body>

    </Modal>
  );
};

export default MailboxUpdateModal;
