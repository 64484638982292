import React from "react";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import NotFound from "../pages/NotFound";
import MailboxPassword from "../pages/MailboxPassword";

// import Forbidden from "../pages/Forbidden";
// import ForgotPassword from "../pages/ForgotPassword";
// import InternalServerError from "../pages/InternalServerError";
// import LockScreen from "../pages/LockScreen";
// import ServiceUnavailable from "../pages/ServiceUnavailable";
// import Signin2 from "../pages/Signin_clear";
// import Signup2 from "../pages/Signup2";
// import VerifyAccount from "../pages/VerifyAccount";

const publicRoutes = [
  { path: "/signin", element: <Signin /> },
  { path: "/signup", element: <Signup /> },
  { path: "/mailboxpassword", element: <MailboxPassword /> },
  // { path: "pages/signin2", element: <Signin2 /> },
  // { path: "pages/Signin_clear", element: <Signup2 /> },
  // { path: "pages/verify", element: <VerifyAccount /> },
  // { path: "pages/forgot", element: <ForgotPassword /> },
  // { path: "pages/lock", element: <LockScreen /> },
  // { path: "pages/error-500", element: <InternalServerError /> },
  // { path: "pages/error-503", element: <ServiceUnavailable /> },
  // { path: "pages/error-505", element: <Forbidden /> },
  { path: "/error-404", element: <NotFound /> }
];

export default publicRoutes;