import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';


const MailboxDelete = ({ domain, email, onDelete }) => {
  const jwt = localStorage.getItem('jwtToken');
  const [message, setMessage] = useState('');

  const handleDelete = async () => {
    setMessage('Excluindo...');

    const url = `https://api.emailfacil.com.br/mailbox/delete/${domain}/${email}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${jwt}`
        },
      });

      if (response.ok) {
        setMessage('A caixa de e-mail foi excluída com sucesso.');
        // Não chame onDelete(email) aqui, aguarde a confirmação no componente pai
      } else {
        const responseData = await response.json();
        setMessage(responseData.error || 'Ocorreu um erro ao excluir a caixa de e-mail.');
      }
    } catch (error) {
      setMessage('Ocorreu um erro. Tente novamente.');
    }
  };

  useEffect(() => {
    // Se a mensagem for "A caixa de e-mail foi excluída com sucesso.", chame onDelete(email)
    if (message === 'A caixa de e-mail foi excluída com sucesso.') {
      onDelete(email);
    }
  }, [message, onDelete, email]);

  return (
    <Card>
      <Card.Body>
        <Form>
          <Button
            style={{ marginTop: '10px' }}
            type="button"  variant="danger"
            onClick={handleDelete}
          >
            Excluir Caixa de E-mail
          </Button>

          {message && <div>{message}</div>}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MailboxDelete;
