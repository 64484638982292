import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";


export default function Domains() {
  

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Domínios</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Domínios</li> */}
            </ol>
            {/* <h4 className="main-title mb-0">Bem vindo</h4> */}
          </div>
        </div>



        <Footer />
      </div>
    </React.Fragment>
  );
}
