import apiServer from "../middleware/AxiosInstance/apiServer";

const domain_url = 'https://api.emailfacil.com.br/domain';

const listDomains = async () => await apiServer.get(`${domain_url}/list`);

const domainServices = {
    listDomains
}

export default domainServices;