import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';

const MailboxUpdate = ({ email }) => {
  const [mailbox, setMailbox] = useState(email); 
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const [message, setMessage] = useState('');
  const jwt = localStorage.getItem('jwtToken');
  const userEmail = ''; 

  const handleNewPasswordChange = (e) => {
    setFormData({ ...formData, newPassword: e.target.value });
  };
 
  const handleConfirmPasswordChange = (e) => {
    setFormData({ ...formData, confirmPassword: e.target.value });
  };

  useEffect(() => {
    setMailbox(email);
    console.log('mailbox no update:', mailbox);
    console.log('password no update:', formData.newPassword);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifique se as senhas coincidem antes de enviar o formulário
    if (formData.newPassword !== formData.confirmPassword) {
      setMessage('As senhas não coincidem. Tente novamente.');
      return;
    }

    setMessage('Enviando...');

    // Extrair domínio do email
    const emailParts = mailbox.split('@'); 
    const domain = emailParts[1]; // Obtém a parte do domínio após o '@'
    const url = `https://api.emailfacil.com.br/mailbox/update/${domain}`;

    const requestData = {
      mailbox: mailbox, // Utilize o email da prop
      password: formData.newPassword,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(requestData) // Envie o objeto como JSON
      });

      const responseData = await response.json();

      if (response.ok) {
        setMessage(responseData.message);
      } else {
        setMessage(responseData.error);
      }
    } catch (error) {
      setMessage('Ocorreu um erro. Tente novamente.');
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit}>

          {/* <Form.Group>
            <Form.Label>Email:</Form.Label>
            <Form.Control type="text" value={mailbox} readOnly />
          </Form.Group> */}

          <Form.Group>
            <Form.Label>Nova Senha:</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              onChange={handleNewPasswordChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirmar Nova Senha:</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {formData.newPassword !== formData.confirmPassword && (
              <Form.Text className="text-danger">As senhas não coincidem.</Form.Text>
            )}
          </Form.Group>

          <Button style={{ marginTop: '10px' }} type="submit">
            Trocar Senha
          </Button>

          {message && <div>{message}</div>}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MailboxUpdate;
