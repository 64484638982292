import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';

const MailboxShare = ({ domain, prefix }) => {
  const [link, setLink] = useState('');
  const [message, setMessage] = useState('');
  const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));
  const [isButtonVisible, setIsButtonVisible] = useState(true); // Controla a visibilidade do botão

  useEffect(() => {
    setMessage('');
  }, []);

  const handleGenerateLink = async () => {
    setMessage('Gerando link...');

    const url = `https://api.emailfacil.com.br/mailbox/share/${domain}/${prefix}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwt}`
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // Usar o token da resposta para construir o link
        const generatedLink = `https://app.emailfacil.com.br/mailboxpassword?token=${responseData.token}&email=${prefix}@${domain}`;
        setLink(generatedLink);
        setMessage('');
        setIsButtonVisible(false); // Oculta o botão após a geração do link
      } else {
        setLink('');
        setMessage(responseData.error || 'Ocorreu um erro ao gerar o link.');
      }
    } catch (error) {
      setLink('');
      setMessage('Ocorreu um erro. Tente novamente.');
    }
  };

  const handleCopyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setMessage('Link copiado para a área de transferência.');
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          {isButtonVisible && (
            <Button
              style={{ marginBottom: '10px' }}
              type="button"
              variant="primary"
              onClick={handleGenerateLink}
            >
              Gerar link
            </Button>
          )}
          <br />
          {link && (
            <>
              <p><strong>Link:</strong></p>
              <p>{link}</p>
              <Button
                style={{ marginTop: '10px' }}
                type="button"
                variant="success"
                onClick={handleCopyToClipboard}
              >
                Copiar link
              </Button>
            </>
          )}
          {message && <div>{message}</div>}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MailboxShare;
