import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. Email Fácil. All Rights Reserved.</span>
      <span>Created by: <Link to="https://leoborlot.com.br" target="_blank">Leo Borlot</Link></span>
    </div>
  )
}