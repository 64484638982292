import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import AuthenticationWrapper from "./components/AuthenticationWrapper/AuthenticationWrapper";
import publicRoutes from "./routes/PublicRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { DomainProvider } from "./context/DomainProvider/DomainProvider";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <AuthenticationWrapper>
                <DomainProvider>
                    <Main />
                </DomainProvider>
              </AuthenticationWrapper>
            }
          />
          {publicRoutes.map((route, index) => (
            <Route
              path={route.path}
              element={route.element}
              key={index}
            />
          ))}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
