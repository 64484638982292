
function fetchAccountData() {
  const jwtToken = localStorage.getItem('jwtToken');
  if (!jwtToken) {
    console.error('jwtToken não encontrado no localStorage');
    return Promise.reject('jwtToken não encontrado no localStorage');
  }

  // Faça a requisição GET usando fetch e retorne uma Promise
  return fetch('https://api.emailfacil.com.br/account/get', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Erro ao obter os dados da conta');
      }
      console.log('my reponser',response);
      return response.json();
    })
    .then((accountData) => {
      sessionStorage.setItem('account_limits', JSON.stringify(accountData));
      return accountData;
    })
    .catch((error) => {
      console.error('Erro ao obter os dados da conta:', error);
      throw error; 
    });
}

export default fetchAccountData;
