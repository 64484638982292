import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Col, Row } from 'react-bootstrap';

const AccountUsageCard = ({ config }) => {
  const {
    chartOptions,
    chartSeries,
    percentageText,
    utilizationText,
    availableCount,
    accountLabelText,
    unityText,
    contractText,
  } = config;

  return (
    <Card className="card-one">
      <Card.Body className="p-3">
        <Row className="g-3 row-cols-auto align-items-center">
          <Col>
            <div className="apex-donut-one">
              <ReactApexChart series={chartSeries} options={chartOptions} width={160} height={160} type="donut" />
              <div>
                <h4 className="ff-numerals text-dark mb-0">{percentageText}<span className="fs-xs text-primary">{unityText}</span></h4>
                <span className="fs-xs text-secondary">{utilizationText}</span>
              </div>
            </div>
          </Col>
          <Col xs="6" xl="6">
            <h2 className="card-value">{availableCount}</h2>
            <h6 className="card-label text-dark fw-semibold mb-3">{accountLabelText}</h6>
            <p className="fs-sm text-secondary mb-0">{contractText}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AccountUsageCard;
