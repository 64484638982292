import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import { useDomain } from '../../context/DomainProvider/DomainProvider';


const MailboxAdd = () => {
const {domain} = useDomain()
const { domain: { domain_name } , domainsList, setDomain } = useDomain();
 
  const [formData, setFormData] = useState({
    mailbox: '',
    password: ''
  });
  
  const [passwordValidation, setPasswordValidation] = useState('');
  const [message, setMessage] = useState('');
//   const [domain, setDomain] = useState(''); 
  const [quota, setQuota] = useState('');
  const [availableQuotas, setavailableQuotas] = useState([]); 
  const [validDomainNames, setValidDomainNames] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState('');
  const jwt = localStorage.getItem('jwtToken');


  useEffect(() => {
    if (domainsList) {
        if (domainsList.length === 0) {
            // setLoading(true);
        } else {
            if (domain_name) {
                // updateMailboxByDomain();
                
            } else {
                if (domainsList[0]) {
                    // setSelectedDomain(domainsList[0].domain_name);                       
                    setDomain(domainsList[0]);
                    console.log('O valor de domain_name:', domain_name);
                    // updateMailboxByDomain();
                }
            }
        }
    }
}, [domainsList]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setFormData({ ...formData, password: newPassword });
  
    // Adicione sua lógica de validação de senha aqui
    if (newPassword.length < 6) {
      setPasswordValidation('A senha deve ter pelo menos 6 caracteres.');
    } else {
      setPasswordValidation('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    
    // Comparar a senha confirmada com a senha principal
    if (newConfirmPassword !== formData.password) {
      setPasswordMismatch('As senhas não coincidem.');
    } else {
      setPasswordMismatch('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Verifique se as senhas coincidem antes de enviar o formulário
    if (passwordMismatch) {
      setMessage("As senhas não coincidem. Tente novamente.");
      return;
    }
  
    // Verifique se a senha atende aos requisitos mínimos antes de enviar o formulário
    if (passwordValidation) {
      setMessage("A senha deve ter pelo menos 8 caracteres.");
      return;
    }
  
    setMessage('Enviando...');
    const url = `https://api.emailfacil.com.br/mailbox/add/${domain_name}`;
  
    // Crie o objeto de dados a ser enviado
    const requestData = {
      mailbox: `${formData.mailbox}@${domain_name}`,
      password: formData.password,
      quota: quota,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(requestData), // Envie o objeto como JSON
      });
      const responseData = await response.json();
      if (response.ok) {
        setMessage(responseData.message);
      } else {
        setMessage(responseData.error);
      }
    } catch (error) {
      setMessage('Ocorreu um erro. Tente novamente.');
    }
  };
  
  useEffect(() => {
    // Aqui, vamos buscar as chaves do objeto 'account_limits.available_mailbox' do sessionStorage
    const accountLimits = JSON.parse(sessionStorage.getItem('account_limits'));
    const availableQuotas = accountLimits ? Object.keys(accountLimits.available_mailbox) : {};  
    console.log('AvaliableCotass', availableQuotas[0]);
    setQuota(availableQuotas[0]);
    setavailableQuotas(availableQuotas)
  }, [jwt]);

  console.log('valid Cotassssss', availableQuotas);

  useEffect(() => {
    // Fazer a solicitação GET para obter a lista de domínios válidos
    fetch('https://api.emailfacil.com.br/domain/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      const validDomains = data.filter((item) => item.is_valid === 1);
      const validDomainNames = validDomains.map((item) => item.domain_name);
      setValidDomainNames(validDomainNames); // Atualize o estado com os domínios válidos
    //   setDomain(validDomainNames[0]);
    })
    .catch((error) => {
      console.error('Erro ao obter a lista de domínios:', error);
    });
  }, [jwt]);
  
  // console.log('Cota', quota);

  // console.log('domain', domain);

  return (
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Quota:</Form.Label>
              <Form.Control as="select" onChange={(e) => setQuota(e.target.value)} value={quota}>
                {availableQuotas.map((q, index) => (
                  <option key={index} value={q}>
                    {q}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Mailbox:</Form.Label>
              <Row className="g-2">
                <Col sm="6">
                  <Form.Control
                    type="text"
                    name="mailbox"
                    value={formData.mailbox}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Col>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    value={'@' + domain?.domain_name}
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handlePasswordChange}
              autoComplete="off"
            />
            {passwordValidation && (
              <Form.Text className="text-danger">{passwordValidation}</Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="off"
            />
            {passwordMismatch && (
              <Form.Text className="text-danger">{passwordMismatch}</Form.Text>
            )}
          </Form.Group>
    
            <Button style={{ marginTop: '10px' }} type="submit">Registrar</Button>
            {message && <div>{message}</div>}
          </Form>
        </Card.Body>
      </Card>
    );
  };

export default MailboxAdd;
