import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate em vez de useHistory
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";

export default function Signin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // Para gerenciar erros de autenticação

  const handleUserName = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.emailfacil.com.br/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }), // Alterado para enviar o username e password
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        localStorage.setItem("jwtToken", token);
        navigate("/dashboard");
      } else {
        setError("Autenticação falhou. Verifique suas credenciais.");
      }
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5">Email Fácil</Link>
              <Card.Title>Gerenciamento de contas</Card.Title>
              <Card.Text>Bem-vindo! Informe seus dados para continuar.</Card.Text>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}> {/* Adicione o manipulador de envio ao formulário */}
                <div className="mb-4">
                  <Form.Label>Usuário</Form.Label>
                  <Form.Control type="text" placeholder="Entre com seu usuário." value={username} onChange={handleUserName} />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password {/* <Link to="">Forgot password?</Link> */}
                  </Form.Label>
                  <Form.Control type="password" placeholder="Entre com sua senha" value={password} onChange={handlePasswordChange} />
                </div>
                <Button type="submit" className="btn-sign">Login</Button>
              </Form>
              {error && <p className="text-danger">{error}</p>} {/* Exibir mensagem de erro se houver */}
            </Card.Body>
            <Card.Footer>
              Ainda não tem uma conta? <Link to="/signup">Criar uma conta</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}
