import { useState, createContext, useContext, useEffect } from "react"
import domainServices from "../../services/domainServices";

const storage_key = 'dashbyte:domain';

const default_domain = {
    domain_id: null,
    domain_name: null,
    is_valid: 0
}

const DomainContext = createContext({
    setDomain: () => { },
    domainsList: null,
    domain: null,
    isSearching: false
});

const useDomain = () => useContext(DomainContext);

const DomainProvider = ({
    children
}) => {
    const [domain, setDomain] = useState(default_domain);
    const [domainsList, setDomainsList] = useState(null);
    const [searchingDomains, setSearchingDomains] = useState(false);

    const updateDomain = async (value) => {
        localStorage.setItem(storage_key, JSON.stringify(value));
        setDomain(value);
    }

    const handleUpdateDomain = (value) => {
        updateDomain(value);
        setDomain(value);
    }

    const getListDomains = async () => {
        setSearchingDomains(true);
        try {
            const list_domains = await domainServices.listDomains();
            setDomainsList(list_domains.data);
        }
        catch (error) {
            setDomainsList(null);
        }
        finally {
            setSearchingDomains(false);
        }
    }

    useEffect(() => {
        getListDomains();
        
        const getSavedDomain = localStorage.getItem(storage_key);
        if (getSavedDomain) {
            updateDomain(JSON.parse(getSavedDomain));
            return
        }
    }, []);

    return (
        <DomainContext.Provider value={{
            domain,
            setDomain: handleUpdateDomain,
            domainsList,
            isSearching: searchingDomains
        }}>
            {children}
        </DomainContext.Provider>
    )
}

export {
    useDomain,
    DomainProvider
}