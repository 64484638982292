import React, { useEffect, useState } from "react";
import {  Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";


export default function Documentation() {
 
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Documentação</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Página de testes de componentes</li> */}
            </ol>
            {/* <h4 className="main-title mb-0">Bem vindo</h4> */}
          </div>
        </div>
        <Card>
          <Card.Body>
            <Card.Title>CONEXÕES</Card.Title>
            <Row>
              <Col>
                <h5>Servidor IMAP:</h5>
                <p>Server/Host: srv01.emailfacil.com.br</p>
                <p>Porta: 143 </p>
                <p>Simple Auth</p>
                <p>Porta: 993 </p>
                <p>TLS Auth - ainda desativada</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Servidor WEBMAIL:</h5>
                <p>Server/Host: https://webmail.valenca.rj.gov.br</p>
                <p>login: endereço do Mailbox (email@valenca.rj.gov.br)</p>
                <p>senha: senha definida pelo administrador.</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
}
