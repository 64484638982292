import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AuthenticationWrapper({ children }) {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(false);
  
  const handleLogout = () => { 
    localStorage.removeItem("jwtToken");
     
    navigate("/signin");
  }

  useEffect(() => { 
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) { 
      navigate("/signin");
    } else {
      // Se o token estiver presente, faça a validação
      fetch("https://api.emailfacil.com.br/auth/validate", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            // A resposta é 200 OK, o token é válido, então permita o acesso às rotas protegidas
            setauthenticated(true)
          } else {
            handleLogout();
          }
        })
        .catch((error) => {
            handleLogout();
        });
    }
  }, [navigate]);

  // Renderize as rotas protegidas somente se o usuário estiver autenticado
  return authenticated ? <>{children}</> : <></>;
}

export default AuthenticationWrapper;



