import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import MailboxAdd from '../MailboxAdd/MailboxAdd';

const MailboxAddModal = ({ show, handleClose }) => {
  const [modalShow, setModalShow] = useState(show); 
  // const [showModalMailboxAdd, setShowModalMailboxAdd] = useState(show);

  useEffect(() => {
    // setShowModalMailboxAdd(show);
    setModalShow(show);
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
        <Modal.Title>Novo Mailbox</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        Preencha os campos abaixo para adicionar uma nova caixa de email à sua conta. 
        <MailboxAdd />
        </Modal.Body>
    </Modal>
    );
};

export default MailboxAddModal;
