import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import MailboxShare from '../MailboxShare/MailboxShare';

const MailboxShareModal = ({ showModal, handleClose, mailbox }) => {
  const [modalShow, setModalShow] = useState(showModal);
  const [selectedMailbox, setSelectedMailbox] = useState(null);
  const [prefix, setPrefix] = useState(''); // Adicione o estado para prefix

  useEffect(() => {
    setModalShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setSelectedMailbox(mailbox);

    const newPrefix = mailbox && mailbox.email ? mailbox.email.split('@')[0] : '';
    setPrefix(newPrefix); // Atualize o estado do prefix aqui
    console.log('prefixo', newPrefix )

  }, [mailbox]);

  const handleCloseModal = () => {
    setModalShow(false);
    handleClose();
  };

  return (
    <Modal show={modalShow} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Gerar link.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{mailbox && mailbox.email}</h3>
        <p>Clique no botão abaixo para gerar um link exclusivo para alteração de senha de mailbox e forma remota.</p>
        {/* Passe o valor de prefix como prop para o componente MailboxShare */}
        <MailboxShare domain={mailbox && mailbox.dominio} prefix={prefix} />
      </Modal.Body>
    </Modal>
  );
};

export default MailboxShareModal;
