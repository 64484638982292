import React, { useEffect, useState } from 'react';
import { Dropdown, Spinner, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useDomain } from '../../context/DomainProvider/DomainProvider';

const DomainSelector = () => {
    const { domain, domainsList, isSearching, setDomain } = useDomain();

    const [selectedDomain, setSelectedDomain] = useState(null); // Estado para controlar a seleção

    useEffect(() => {
        if (domainsList && domainsList.length > 0) {
            setSelectedDomain(domainsList[0].domain_name);
        }
    }, [domainsList]);

    

    const handleSelect = (domain_name) => {
        const get_domains_object = domainsList.find(item => item.domain_name === domain_name)
        setDomain(get_domains_object);
    }

    return (
        <OverlayTrigger
            placement="left"
            overlay={
                <Tooltip>
                    Domínios disponíveis
                </Tooltip>
            }
        >
            <Dropdown className="ms-3 ms-xl-4" align="end" onSelect={handleSelect} >
                <Dropdown.Toggle variant="primary" id="dropdown-basic" disabled={Object.keys(domain).length <= 0}>
                    {isSearching ? (
                        <>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading...
                        </>
                    ) : Object.keys(domain).length <= 0 ? "Nenhum domínio encontrado" : (
                        selectedDomain ?? 'Selecione um domínio'
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {domainsList?.map((domain_item, index) => (
                        <Dropdown.Item
                            key={index}
                            eventKey={domain_item.domain_name}
                            href="#"
                            className={domain_item.is_valid ? "" : "text-danger"}
                        >
                            @{domain_item.domain_name}
                        </Dropdown.Item>
                    ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </OverlayTrigger>
    );
};

export default DomainSelector;
