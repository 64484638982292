import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import MailboxDelete from '../MailboxDelete/MailboxDelete'; // Importe o componente MailboxDelete

const MailboxDeleteModal = ({ showModal, handleClose, mailbox, domain, onDelete }) => { 
    const [modalShow, setModalShow] = useState(showModal);
    const [selectedMailbox, setSelectedMailbox] = useState(null);

    useEffect(() => {
        setModalShow(showModal);
    }, [showModal]);

    useEffect(() => {
        setSelectedMailbox(mailbox);
    }, [mailbox]);

    const handleCloseModal = () => {
        setModalShow(false);
        handleClose();
    };

    return (
        <Modal show={modalShow} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Excluir Mailbox</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ao clicar no botão abaixo a mailbox abaixo será excluída <b>definitivamente.</b></p>
                <h3>{mailbox && mailbox.email}</h3>

                <MailboxDelete domain={domain} email={selectedMailbox && selectedMailbox.email} onDelete={onDelete} />
            </Modal.Body>
        </Modal>
    );
};

export default MailboxDeleteModal;
