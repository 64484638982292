import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import MailboxAddModal from "../components/MailboxAddModal/MailboxAddModal";
import MailboxUpdateModal from "../components/MailboxUpdateModal/MailboxUpdateModal";

export default function Test() {
  const [showAddModal, setShowAddModal] = useState(false); // Estado para o primeiro modal
  const [showUpdateModal, setShowUpdateModal] = useState(false); // Estado para o segundo modal

  // Funções para mostrar e fechar o primeiro modal
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // Funções para mostrar e fechar o segundo modal
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Página de testes de componentes</li>
            </ol>
            <h4 className="main-title mb-0">Bem vindo</h4>
          </div>
        </div>

        {/* Botões para abrir os modais */}
        <Button variant="primary" onClick={handleShowAddModal}>Abrir Modal 1</Button>
        <Button variant="secondary" onClick={handleShowUpdateModal}>Abrir Modal 2</Button>

        {/* Modais */}
        <MailboxAddModal show={showAddModal} handleClose={handleCloseAddModal} />
        <MailboxUpdateModal show={showUpdateModal} handleClose={handleCloseUpdateModal} />

        <Footer />
      </div>
    </React.Fragment>
  );
}
