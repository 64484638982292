import React from "react";
import { Routes, Route } from "react-router-dom";
import { Outlet, useLocation, Navigate } from "react-router-dom"; // Importe o Navigate
import Sidebar from "./Sidebar";
import protectedRoutes from "../routes/ProtectedRoutes"; // Importe a partir do diretório pai
import NotFound from "../pages/NotFound";

export default function Main() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // Verifique se a URL é '/' e redirecione para '/dashboard'
  if (pathname === '/') {
    return <Navigate to="/dashboard" replace />;
  }

  // Restante do código para ajustar a barra lateral, etc.
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");
  bc.remove("sidebar-show");
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Sidebar />
      <Routes>
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={route.element}
              key={index}
            />      
          )
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Outlet />
    </React.Fragment>
  )
}
