import React, { useEffect, useState } from 'react';
import { Card, Table, ProgressBar, Form, Nav, Dropdown, Spinner } from 'react-bootstrap';

import './MailboxListing.css';
import MailboxUpdateModal from '../MailboxUpdateModal/MailboxUpdateModal';
import MailboxDeleteModal from '../MailboxDeleteModal/MailboxDeleteModal'; 
import MailboxShareModal from '../MailboxShareModal/MailboxShareModal';

import { mailboxServices } from '../../services/mailboxServices';
import { useDomain } from '../../context/DomainProvider/DomainProvider';


const MailboxListing = () => {
    const { domain: { domain_name }, domainsList, setDomain } = useDomain();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMailbox, setSelectedMailbox] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);

    const handleShowShareModal = (mailbox) => {
        setSelectedMailbox(mailbox);
        setShowShareModal(true); // Defina showShareModal como true ao abrir o modal
      };
    
      const handleCloseShareModal = () => {
        setSelectedMailbox(null);
        setShowShareModal(false); // Defina showShareModal como false ao fechar o modal
      };

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleMailboxDeleted = (deletedEmail) => {
        setData((prevData) => prevData.filter((mailbox) => mailbox.email !== deletedEmail));
        setShowDeleteModal(false); 
      };

    const handleShowUpdateModal = (mailbox) => {
        setSelectedMailbox(mailbox);
        setShowUpdateModal(true);
    };

    const handleCloseUpdateModal = () => {
        setSelectedMailbox(null);
        setShowUpdateModal(false);
    };

    const toggleDropdown = (mailbox) => {
        setDropdownOpen(!dropdownOpen);
        setSelectedMailbox(mailbox);
    };

    async function updateMailboxByDomain() {
        try {
            setLoading(true);
            const list_mailbox = await mailboxServices.getMailboxByDomain(domain_name);
            setData(list_mailbox.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (domainsList) {
            if (domainsList.length === 0) {
                setLoading(true);
            } else {
                if (domain_name) {
                    // updateMailboxByDomain();
                    
                } else {
                    if (domainsList[0]) {
                        setDomain(domainsList[0]);
                        console.log('O valor de domain_name:', domain_name);
                        updateMailboxByDomain();
                    }
                }
            }
        }
    }, [domainsList]);


    useEffect(() => {
        console.log('name = domain_name', domain_name);
        if (domain_name) {
            updateMailboxByDomain();
        }
    }, [domain_name]);


    useEffect(() => {
        if (data.length > 0) {
            setNoMailboxesFound(false);
        } else {
            setNoMailboxesFound(true);
        }
    }, [data]);

    const [noMailboxesFound, setNoMailboxesFound] = useState(false);



    return (
        <Card className="card-one">
            <Card.Header>
                <Card.Title as="h6">Mailboxes ativas.</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
            </Card.Header>
            <Card.Body>
            {loading ? (
                    <div className='text-center'> 
                        <Spinner className='text-primary'/>
                    </div>
                ) : data.length <= 0 ? (
                    <div>
                        <p className='text-center'>Nenhuma mailbox encontrada</p>
                    </div>
                ) : (
                    <Table className="table-agent mb-0" responsive>
                        
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check type="checkbox" />
                                </th>
                                <th>Endereço</th>
                                <th>Consumo</th>
                                <th>Quota</th>
                                <th>Emails</th>
                                <th>Alias</th>
                                <th>Qualidade</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check type="checkbox" />
                                    </td>
                                    <td>
                                        <div>
                                            <h6 className="mb-0">{item.email}</h6>
                                            <span className="fs-xs text-secondary">{item.email}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <ProgressBar striped variant="success" now={item.porcentagem} className="mb-2" />
                                    </td>
                                    <td><span className="ff-numerals">{item.total}</span></td>
                                    <td><span className="ff-numerals">{'*/*'}</span></td>
                                    <td>
                                        <div>
                                            {item.aliases && item.aliases.map((alias, ind) => (
                                                <span key={ind} className="fs-xs text-secondary">{alias}</span>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex gap-1 text-warning">
                                            {Array.from({ length: 5 }).map((_, ind) => (
                                                <i key={ind} className={"ri-star-line"}></i>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-end">
                                            <Dropdown show={dropdownOpen && selectedMailbox === item} autoClose="outside">
                                                <Dropdown.Toggle variant="link" id="mailbox-actions" className="dropdown-toggle-icon-none">
                                                    <i className="ri-more-2-fill" onClick={() => toggleDropdown(item)}></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowUpdateModal(item)}>Alterar senha</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowShareModal(item)}>Gera link de alteração de senha</Dropdown.Item>
                                                    {/* <Dropdown.Item disabled>Gera link de alteração de senha</Dropdown.Item> */}
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item disabled>Alterar Proprietário</Dropdown.Item>
                                                    <Dropdown.Item disabled>Alterar quota</Dropdown.Item>
                                                    <Dropdown.Item disabled>Adicionar um alias</Dropdown.Item>
                                                    <Dropdown.Item disabled>Remover um alias</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={handleShowDeleteModal}>Excluir Mailbox</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
              )}
            </Card.Body>
            <MailboxUpdateModal showModal={showUpdateModal} handleClose={handleCloseUpdateModal} mailbox={selectedMailbox} />
            <MailboxShareModal showModal={showShareModal} handleClose={handleCloseShareModal} mailbox={selectedMailbox} />
            <MailboxDeleteModal
                showModal={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                mailbox={selectedMailbox}
                onDelete={handleMailboxDeleted}
                domain={domain_name}
            />

        </Card>
    );
};

export default MailboxListing;
