import React, { useEffect, useState } from "react";
import { Card, Col, Row, Nav, Table, ProgressBar, Form } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";


import ClockCounter from "../components/ClockCounter/ClockCounter";
import fetchAccountData from "../components/fetchAccountData/fetchAccountData";
import MailboxListing from "../components/MailboxListing/MailboxListing";
import MailboxAddModal from "../components/MailboxAddModal/MailboxAddModal";
// import MailboxUpdateModal from "../components/MailboxUpdateModal/MailboxUpdateModal";


async function fetchAndCalculateAccountData() {
  try {
    const accountData = await fetchAccountData();
    const calculatedData = await AccountCalc(accountData);
  } catch (error) {
    console.error("Erro ao buscar ou calcular dados da conta:", error);
  }
}

function AccountCalc() {
  const [valores, setValores] = useState({
    CountTotalAllLimit: 0,
    CountTotalAvailable: 0,
    CountRestMailbox: 0,
    SizeUsedMailbox: 0,
    SizeTotaMailbox: 0, // Novo valor para o total de gigabytes
  });

  useEffect(() => {
    const accountLimitsJSON = sessionStorage.getItem('account_limits');
    if (accountLimitsJSON) {
      const accountLimits = JSON.parse(accountLimitsJSON);

      // Calcule os valores desejados aqui
      const CountTotalAllLimit = Object.values(accountLimits.all_limit_mailbox).reduce((total, value) => total + value, 0);
      const CountTotalAvailable = Object.values(accountLimits.available_mailbox).reduce((total, value) => total + value, 0);
      const CountRestMailbox = CountTotalAllLimit - CountTotalAvailable;
      
      // Novo cálculo para o espaço utilizado
      const SizeUsedMailbox = Object.keys(accountLimits.all_limit_mailbox).reduce((total, key) => {
        const totalSize = parseInt(key); // Converte a chave (por exemplo, '15G') em um número
        const usedSize = (accountLimits.all_limit_mailbox[key] - accountLimits.available_mailbox[key]) * totalSize;
        return total + usedSize;
      }, 0);
      
      // Novo cálculo para o total de gigabytes
      const SizeTotaMailbox = Object.keys(accountLimits.all_limit_mailbox).reduce((total, key) => {
        const totalSize = parseInt(key); // Converte a chave (por exemplo, '15G') em um número
        const count = accountLimits.all_limit_mailbox[key];
        return total + totalSize * count;
      }, 0);

      // Atualize o estado com os resultados
      setValores({
        CountTotalAllLimit,
        CountTotalAvailable,
        CountRestMailbox,
        SizeUsedMailbox,
        SizeTotaMailbox, // Adiciona o valor do total de gigabytes
      });
    }
  }, []);

  return valores; // Retorne os resultados calculados
}


export default function EmailFacilDefault() {
  const [formData, setFormData] = useState({});
  const [passwordValidation, setPasswordValidation] = useState('');

  const [showAddModal, setShowAddModal] = useState(false); // Estado para o primeiro modal
  // const [showUpdateModal, setShowUpdateModal] = useState(false); // Estado para o segundo modal

  // Funções para mostrar e fechar o primeiro modal
  const handleShowAddModal = () => {
    setShowAddModal(true);
    setFormData({
      mailbox: '',
      password: '',
    });
    setPasswordValidation('');
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // // Funções para mostrar e fechar o segundo modal
  // const handleShowUpdateModal = () => {
  //   setShowUpdateModal(true);
  // };

  // const handleCloseUpdateModal = () => {
  //   setShowUpdateModal(false);
  // };

  const handleCommand = (command) => {
    if (command === 'openMailboxAddModal') {
      handleShowAddModal(); // Abre o modal quando o comando é 'openMailboxModal'
      console.log('executou o comando');
    } 
    // if (command === 'openMailboxUpdate') {
    //   handleShowUpdateModal(); // Abre o modal quando o comando é 'openMailboxModal'
    //   console.log('executou o comando');
    // } 
  };

  useEffect(() => {
    fetchAndCalculateAccountData();

  }, []);

    // AccountCalc();

  const { CountTotalAllLimit, CountTotalAvailable, CountRestMailbox, SizeUsedMailbox, SizeTotaMailbox  } = AccountCalc();
  // console.log('CountTotalAllLimit', CountTotalAllLimit);
  // console.log('CountTotalAvailable', CountTotalAvailable);
  //   console.log('SizeUsedMailbox', SizeTotaMailbox - SizeUsedMailbox);
  // console.log('SizeTotaMailbox', SizeTotaMailbox );

  // Agora você pode usar os resultados para configurar a prop config
  const account_use = {
    chartOptions: {
      parentHeightOffset: 0,
      chart: { parentHeightOffset: 0 },
      colors: ['#4c5366', '#e5e9f2'],
      dataLabels: { enabled: false },
      legend: { show: false }
    },
    chartSeries: [CountTotalAllLimit, CountTotalAvailable ],
    percentageText: `${((CountRestMailbox / CountTotalAllLimit ) * 100).toFixed(0)}`,
    unityText: '%',
    utilizationText: 'Utilizados',
    availableCount: `${CountTotalAvailable}`,
    accountLabelText: 'Mailboxes disponíveis',
    contractText: '* Calculo de acordo com as quotas definidas por cada mailbox ativa.',
  };

  const space_use = {
    chartOptions: {
      parentHeightOffset: 0,
      chart: { parentHeightOffset: 0 },
      colors: ['#4c5366', '#e5e9f2'],
      dataLabels: { enabled: false },
      legend: { show: false }
    },
    chartSeries: [SizeTotaMailbox, SizeTotaMailbox - SizeUsedMailbox ],
    percentageText: `${SizeUsedMailbox}`,
    unityText: 'GB',
    utilizationText: 'Utilizados',
    availableCount: `${SizeTotaMailbox}`,
    accountLabelText: 'Espaço aprovisionado',
    contractText: '* Calculo de acordo as quotas definidas por cada mailbox ativa.',
  };
  
  return (
    <React.Fragment>
        <MailboxAddModal show={showAddModal} handleClose={handleCloseAddModal} />
        {/* <MailboxUpdateModal show={showUpdateModal} handleClose={handleCloseUpdateModal} /> */}

      <Header  />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Dashboard</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Helpdesk Service</li> */}
            </ol>
            <h4 className="main-title mb-0">Bem vindo</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col sm="12" md="8" xl="8">

            <Row className="g-3 mb-3">   
              <Col sm="12" md="6" xl="6">
              <ClockCounter config={account_use} />     
                </Col>
                <Col sm="12" md="6" xl="6">
                <ClockCounter config={space_use} />    
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col xs="12">
                  <MailboxListing />
              </Col>
            </Row>
            
          </Col>

          <Col sm="12" md="4" xl="4">
            <Row className="g-3 mb-3">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Score de qualidade no uso.</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-baseline gap-2 mb-0">
                    <h1 className="card-value mb-0">0.0</h1>
                    <ProgressBar className="flex-fill ht-5">
                      <ProgressBar now={20} />
                      <ProgressBar now={20} variant="success" />
                      <ProgressBar now={20} variant="warning" />
                      <ProgressBar now={20} variant="info" />
                      <ProgressBar now={20} variant="danger" />
                    </ProgressBar>
                  </div>
                  <p className="fs-sm">The percentage value assigned to the operating metric.</p>
                  <Table className="table-ratings mb-0">
                    <tbody>
                      {[
                        {
                          "dot": "primary",
                          "label": "Excellent",
                          "count": "0",
                          "percent": "20%"
                        }, {
                          "dot": "success",
                          "label": "Very Good",
                          "count": "0",
                          "percent": "20%"
                        }, {
                          "dot": "warning",
                          "label": "Good",
                          "count": "0",
                          "percent": "20%"
                        }, {
                          "dot": "info",
                          "label": "Fair",
                          "count": "0",
                          "percent": "20%"
                        }, {
                          "dot": "danger",
                          "label": "Poor",
                          "count": "0",
                          "percent": "20%"
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td><span className={"badge-dot bg-" + item.dot}></span></td>
                          <td><strong>{item.label}</strong></td>
                          <td>{item.count}</td>
                          <td>{item.percent}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </Row>

            <Row className="g-3 mb-3">
            {[
            {
              "bg": "primary",
              "icon": "ri-mail-add-line",
              "value": "387",
              "percent": "+3.82%",
              "success": true,
              "label": "Novo Mailbox",
              "description": "Adicionar uma nova caixa de email à sua conta.",
              "command": "openMailboxAddModal",
              "agents": 8
            }
            // ,{
            //   "bg": "primary",
            //   "icon": "ri-global-line",
            //   "value": "296",
            //   "percent": "-0.08%",
            //   "success": false,
            //   "label": "Novo Dominio",

            //   "agents": 5
            // }
            // ,{
            //   "bg": "ui-03",
            //   "icon": "ri-shopping-cart-line",
            //   "value": "198",
            //   "percent": "-0.03%",
            //   "success": false,
            //   "label": "Novo contrato",

            //   "agents": 9
            // }
          ].map((item, index) => (
            <Col md="12" sm="4" key={index}>
              <Card className="card-one">
                <Card.Body className="p-2" onClick={() => handleCommand(item.command)}>
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div
                      className={"helpdesk-icon text-white bg-" + item.bg}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className={item.icon}></i>
                    </div>
                    <div className="md-6 m-2">
                      <h3 className="card-value d-flex align-items-baseline mb-0">{item.label}</h3>
                      <label className="card-label fs-sm fw-medium mb-1">{item.description}</label>
                      <div className="mutual-badge">
                        {/* <label>{item.agents} support agents</label> */}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}

            </Row>

          </Col>  

       </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}